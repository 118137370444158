<template>
  <div class="ta-general-detail">
    <h3 class="mb-4">Tenancy Agreement</h3>

    <div class="row">
      <!-- General -->
      <div class="col-12 md-col-6 mb-3">
        <div class="row">
          <display-data
            class="col-12"
            label="Agreement Date"
            :content="getDate(tenancyAgreement.agreementDate)"
          ></display-data>
          <display-data
            class="col-12"
            label="Terms"
            :content="`${tenancyAgreement.terms} year`"
          ></display-data>
          <display-data
            class="col-12"
            label="Commencement Date"
            :content="getDate(tenancyAgreement.commencementDate)"
          ></display-data>
          <display-data
            class="col-12"
            label="Expiry Date"
            :content="getDate(tenancyAgreement.dateOfExpiry)"
          ></display-data>
          <display-data
            class="col-12"
            label="Rent Free Period"
            :content="
              getRentFreePeriod(
                tenancyAgreement.rentFreePeriodFrom,
                tenancyAgreement.rentFreePeriodTo
              )
            "
          ></display-data>
          <display-data
            class="col-12"
            label="purpose"
            :content="tenancyAgreement.purpose"
          ></display-data>
          <display-data
            class="col-12"
            label="Further Term"
            :content="`${tenancyAgreement.furtherTerm} year at ${tenancyAgreement.rentalFurtherTerm}`"
          ></display-data>
          <display-data
            class="col-12"
            label="Offer To Lease Type"
            :content="tenancyAgreement.offerToLeaseType"
          ></display-data>
          <display-data
            class="col-12"
            label="Special Conditions"
            :content="tenancyAgreement.specialConditions"
          ></display-data>
        </div>
      </div>

      <!-- Amounts -->
      <div class="col-12 md-col-6 mb-3">
        <div class="row">
          <display-data
            class="col-12"
            label="Monthly Rent"
            :content="`RM ${tenancyAgreement.monthlyRent}`"
            :empty="_.isEmpty(tenancyAgreement.monthlyRent)"
          ></display-data>
          <display-data
            class="col-12"
            label="Security Deposit"
            :content="`RM ${tenancyAgreement.securityDeposit}`"
            :empty="_.isEmpty(tenancyAgreement.securityDeposit)"
          ></display-data>
          <display-data
            class="col-12"
            label="Utilities Deposit"
            :content="`RM ${tenancyAgreement.utilitiesDeposit}`"
            :empty="_.isEmpty(tenancyAgreement.utilitiesDeposit)"
          ></display-data>
          <display-data class="col-12" label="Other Deposit">
            <div v-if="!_.isEmpty(tenancyAgreement.otherDeposit)">
              {{ `RM ${tenancyAgreement.otherDeposit}` }}
              {{ tenancyAgreement.remark }}
            </div>
            <div v-else>No other deposit</div>
          </display-data>
          <display-data
            class="col-12"
            label="Tax Rate"
            :content="tenancyAgreement.taxRate"
          >
          </display-data>
          <display-data
            class="col-12"
            label="Tax Amount"
            :content="tenancyAgreement.taxAmount"
          >
          </display-data>
        </div>
      </div>

      <div class="col-12 mb-3">
        <download-file
          label="Documents"
          :downloadItem="tenancyAgreement.offerToLeaseDocuments"
          filenamePrefix="rent_document_"
        ></download-file>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    DownloadFile: () => import("@/components/GlobalComponents/DownloadFiles")
  },
  mixins: [],
  props: {
    tenancyAgreement: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    getDate(date) {
      let parsedDate = this.$dayjs(date, "YYYY-MM-DD");
      return parsedDate.isValid() ? parsedDate.format("DD-MM-YYYY") : null;
    },
    getRentFreePeriod(from, to) {
      let fromDate = this.getDate(from);
      let toDate = this.getDate(to);

      if (fromDate && toDate) {
        return `${fromDate} to ${toDate}`;
      } else {
        return null;
      }
    }
  }
};
</script>

<style lang="scss"></style>
